@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}

html {
  scroll-behavior: smooth;
}

body {
  @apply text-lg
}

/* nav li a {
  @apply px-4 py-5 text-lg;
} */

/* nav li a:hover {
  color: #4FFA13;
} */

.mobile-nav {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-blue-900 duration-500 z-50;
}
/* .mobile-nav {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-green-500 duration-500;
} */