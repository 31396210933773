
  .heros_section {
    position: relative; 
    background-color: transparent;
  }
  
  .heros_section video {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    @apply  z-0;
  }
  
  .heros_section .flex {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white; 
  }
  .about-hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 700px; 
    background: url('../../images/about.jpg');
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: cover;
    width: 100%;
  }
  
  .about-hero {
    color: white;
    font-size: 3rem;
  }
  /* .about-hero{
    background: url('../../images/about.jpg');
    background-repeat: no-repeat;
    object-fit: contain;
    background-size: cover;
    width: 100%;
  } */
 
  .animated-button {
    animation: popInOut 0.5s infinite alternate, colorChange 1s infinite alternate;
  }
  
  @keyframes popInOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }
  
  @keyframes colorChange {
    0% {
      background-color: #4FFA13; /* Green */
    }
    100% {
      background-color: rgb(4, 250, 4); /* Black */
    }
  }


  /* .bi-embedded {
    display: inline-block;
    width: 90%;
    height: 800px;
    vertical-align: -0.125em;
    fill: currentColor;
    overflow: hidden;
  } */
  .bi-embedded {
    display: inline-block;
    width: 90%;
    height: 800px;
    vertical-align: -0.125em;
    fill: currentColor;
    overflow: hidden;
  }
