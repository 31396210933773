/* CarbonCommitmentPage.css */

/* Global styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  /* Container styles */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Title styles */
  .title {
    padding: 50px 0;
    text-align: center;
    font-weight: bold;
    color: #333;
  }
  
  /* Subtitle styles */
  .subtitle {
    text-align: center;
    font-weight: bold;
    color: #555;
  }
  
  /* Paragraph styles */
  .paragraph {
    margin-bottom: 20px;
    color: #0a0202;
  }
  .visual-insights-section .section-title {
  font-weight: bold;
}
  /* Section title styles */
  .section-title {
    margin-top: 40px;
    margin-bottom: 20px;
    color: #333;
     font-weight: bold;
    text-align: center;
  }
  
  /* Term container styles */
  .term-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  /* Term styles */
  .term {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
  }
  
  .term h3 {
    margin-top: 0;
    color: #0cda16;
  }
  
  .term p {
    color: #555;
  }
  
/* Solution section styles */
.solution-section {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-top: 40px;
}

/* Solution section title styles */
.solution-section .section-title {
  color: #333;
  margin-top: 0;
}

/* Solution section paragraph styles */
.solution-section .paragraph {
  color: #555;
  margin-bottom: 20px;
}
.visual-insights-section {
  margin-top: 40px;
}

/* Infographics container styles */
.infographics-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Individual infographic styles */
.infographic {
  width: 48%; /* Adjust as needed */
  max-height: 1000px; /* Adjust as needed */
}
